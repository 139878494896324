import React, { useEffect } from 'react'
import './ViewWellbeingReport.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import editBtn from '../../../assets/images/edit.svg'
import openEyeBtn from '../../../assets/images/eye-open.svg'
import closeEyeBtn from '../../../assets/images/eye-close.svg'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { downloadWellbeingReport, openHealthAssessment, updateWllbeingReportVisibleStatus, viewWellbeingReport } from '../../../redux/actions/GuestDataActions/WellbeingReportActions'
import { CircularProgress, Tooltip } from '@mui/material'
import { getGuestDetails } from '../../../redux/actions/GuestDataActions/GuestDetails'
import  {ReactComponent as LeftArrow} from '../../../assets/images/left-arrow.svg'
import  {ReactComponent as RightArrow} from '../../../assets/images/right-arrow.svg'
import  pdfIcon from '../../../assets/images/pdf.svg'
import moment from 'moment'
import * as routes from '../../../routes/route'
import GuestDetailsHeader from '../../../components/GuestDetailsHeader/GuestDetailsHeader'
import ChangeVisibilityModal from '../../../components/ChangeVisibilityModal/ChangeVisibilityModal'
import { downloadMedia } from '../../../utils/helpers/downloadMedia'
import getPermissions from '../../../utils/helpers/getPermissions'
import WarningModal from '../../../components/WarningModal/WarningModal'
import prelimAdd from '../../../assets/images/prelimAdd.svg'
import { CLEAR_WELLBEING_REPORT } from '../../../redux/constants'

function ViewWellbeingReport() {
  const location = useLocation()
  const {type, guestId, userProgramId, user_self_assessment_id, currentReportIndexstate, prevDisabled, nextDisabled, nextDate, is_self_assessment_enabled, is_self_assessment_active} = location.state;
  const [eye, setEye] = useState(false)
  const [allReportsId, setAllReportsId] = useState(null)
  const [isHealthAssessmentActive, setIsHeathAssessmentActive] = useState(is_self_assessment_active)
  const [currentReportIndex, setCurrentReportIndex] = useState(currentReportIndexstate || 0)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {wellbeingReport, loading, downloadLoader} = useSelector(state=> state.wellbeingReportReducer)
  const guestDetailsReducer = useSelector(state=>state.guestDetailsReducer)
  const guestDetails = guestDetailsReducer?.guestDetails
  const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
  const canWrite = (permissions?.wellbeing_report?.permissions || '').includes('write');
  const [modal, setModal] =useState(false)
  const [visibilityModal, setVisibilityModal] =useState(false)
  const {updateLoader} = useSelector(state=> state.wellbeingReportReducer)

  
  const handlePrevBtn = ()=>{
    navigate(routes.VIEW_WELLBEING_REPORT_ENDPOINT, { 
      replace: true, 
      state: {guestId, userProgramId, user_self_assessment_id: allReportsId?.[currentReportIndex+1]?.user_self_assessment_id, currentReportIndexstate: currentReportIndex+1, prevDisabled: currentReportIndex+1 >= allReportsId?.length-1, is_self_assessment_enabled, is_self_assessment_active, type}})
  }

  const handleNextBtn = ()=>{
    navigate(routes.VIEW_WELLBEING_REPORT_ENDPOINT, { 
      replace: true, 
      state: {guestId, userProgramId, user_self_assessment_id: allReportsId?.[currentReportIndex-1]?.user_self_assessment_id, currentReportIndexstate: currentReportIndex-1, nextDisabled: 0 === currentReportIndex-1, nextDate: allReportsId?.[currentReportIndex-1]?.assessment_date,  is_self_assessment_enabled, is_self_assessment_active, type}})
  }

  const handleEye=()=>{
    setEye(!eye); 
    dispatch(updateWllbeingReportVisibleStatus({user_self_assessment_id: wellbeingReport?.user_self_assessment_id, visible_status: !eye}))
    setVisibilityModal(false)
  }

  const downloadPDF=()=>{
    const onSuccessCallback = (data)=>{
      downloadMedia({data, fileName: `${guestDetailsReducer.guestDetails?.first_name} ${guestDetailsReducer.guestDetails?.last_name} - Health Assessment Report.pdf`})
    }
    const data = {
      guestId, 
      userProgramId, 
      user_self_assessment_id,
      onSuccessCallback
    }
    dispatch(downloadWellbeingReport(data))
  }

  const handleOpenAssessment = ()=>{
    dispatch(openHealthAssessment({
        userProgramId: userProgramId,
        status: 1,
        onSuccessCallback:()=>{
            setModal(false)
            navigate(
              routes.VIEW_WELLBEING_REPORT_ENDPOINT, {
                state: {type, guestId, userProgramId, user_self_assessment_id, currentReportIndexstate, prevDisabled, nextDisabled, nextDate, is_self_assessment_enabled, is_self_assessment_active: 1},
                replace: true, 
              },
            )
            setIsHeathAssessmentActive(true)
        }
    }))
}

  useEffect(()=>{
    dispatch(getGuestDetails({guestId}))
    if(isHealthAssessmentActive){
      if(user_self_assessment_id)
        dispatch(viewWellbeingReport({guestId, userProgramId, user_self_assessment_id}))
      else
        dispatch(viewWellbeingReport({guestId, userProgramId}))
    }
    return (()=>{
      dispatch({type: CLEAR_WELLBEING_REPORT})
    })
  },[])

  useEffect(()=>{
    if(wellbeingReport?.all_report_ids){
      setEye(wellbeingReport?.is_result_visible_to_guest)
      setAllReportsId(wellbeingReport?.all_report_ids)
      if(!user_self_assessment_id)
        setCurrentReportIndex(wellbeingReport?.all_report_ids?.findIndex(x => x.user_self_assessment_id === wellbeingReport?.user_self_assessment_id))
    }
  },[wellbeingReport])

   
  return (
    <div className="view-report-container">
      <div className="container h-100">
        <div className="view-report-heading">
          Health Assessment Report
        </div>

        {   
          guestDetailsReducer.loading && (guestId !== guestDetailsReducer.guestDetails?.user_id)?
          <div className="loader">
              <CircularProgress sx={{'color': '#74613C'}}/>
          </div>
          :
            <>
              <div className="pt-4 pb-5">
                <div className="view-report-content">
                  {
                    guestDetails 
                    &&
                    <GuestDetailsHeader guestId={guestId} />
                  }
                  <div className="guest-report-data">
                    <div className="prev-next-btn d-flex justify-content-between">
                      <button type='button' disabled={prevDisabled || loading ||(!user_self_assessment_id &&(!wellbeingReport?.all_report_ids ||  wellbeingReport?.all_report_ids?.length<=1 || (currentReportIndex === 1 && wellbeingReport?.all_report_ids?.length<=2)))} className='prev-btn' onClick={handlePrevBtn}><LeftArrow/>  Prev</button>
                      <div className='time'> {loading ? '' : wellbeingReport?.assessment_filled_datetime ? moment(wellbeingReport?.assessment_filled_datetime, "YYYY-MM-DD hh:mm:ss").format("MMM DD, YYYY") : moment(nextDate).format("MMM DD, YYYY")}</div>
                      <button type='button' disabled={nextDisabled || loading ||(!user_self_assessment_id &&(!wellbeingReport?.all_report_ids ||  wellbeingReport?.all_report_ids?.length<=1))} className='next-btn' onClick={handleNextBtn}>Next <RightArrow/></button>
                    </div>

                    {
                      loading ?
                      <div className="loader">
                          <CircularProgress sx={{'color': '#74613C'}} size={20}/>
                      </div>
                      :

                      <>
                        {
                          Object.keys(wellbeingReport).length === 0
                          ?
                          <div className="loader">
                            {
                              !!is_self_assessment_enabled && !isHealthAssessmentActive && type==="upcoming" && getPermissions("wellbeing_report") !=="hide" ?
                              <div data-testid="add-btn" className="add" onClick={isHealthAssessmentActive ? undefined : ()=>setModal(true)}>
                                <img src={prelimAdd} alt="" />
                                <div className='text'>Open Health Assessment</div>
                              </div>
                              :
                              "Awaiting: Health Assessment to be done."
                            }
                          </div>
                          :
                          <>
                            <div className="status">
                              <div>
                                <div className="data-row">
                                  <div className="title">Prakruti:</div>
                                  <div className="value">{wellbeingReport?.prakruti}</div>
                                </div>
                              </div>

                              <div>
                                <div className="data-row">
                                  <div className="title">Agni (Digestive Capacity):</div>
                                  <div className="value">{wellbeingReport?.agni_type}</div>
                                </div>
                              </div>

                              <div>
                                <div className="data-row">
                                  <div className="title">Koshta (Gut Behaviour):</div>
                                  <div className="value">{wellbeingReport?.koshtha_type}</div>
                                </div>
                              </div>
                      
                          </div>

                          <div className="hr"></div>

                          <div className="last-update">
                            <div>
                              <div className="data-row-1">
                                <div className="title">Your overall Score: &nbsp;</div>
                                <div className="value">{wellbeingReport?.over_all_wellness_score}%</div>
                              </div>
                            </div>
                            {
                              canWrite
                              ?
                              <div className="icons">
                                <div className="vhr"></div>
                                {
                                  downloadLoader ?
                                  <CircularProgress sx={{'color': '#5ebd78', marginRight: '2px'}} size={18} />
                                  :
                                  <Tooltip title="Download PDF">
                                    <div className='me-2' style={{width: '25px'}} onClick={downloadPDF}>
                                      <img alt="print-btn" className="" src={pdfIcon}/>
                                    </div>
                                  </Tooltip>
                                }
                                <img src={eye? openEyeBtn : closeEyeBtn} alt="eye-btn" className="" onClick={()=>setVisibilityModal(true)}/>
                                <img src={editBtn} alt="edit-btn"
                                  onClick={()=>{navigate(routes.EDIT_WELLBEING_REPORT_ENDPOINT, {state: {user_self_assessment_id: wellbeingReport.user_self_assessment_id, guestId, gender: wellbeingReport?.gender, guestName: wellbeingReport?.first_name}})}}
                                />
                              </div>
                              :
                              null
                            }
                          </div>

                          <div className="hr"></div>

                          <div className="data-row-2 heading">
                            <div className="title">Assessment Area</div>
                            <div className="value">Percentage</div>
                          </div>

                          {
                            wellbeingReport?.section_scores?.map((item, index)=>(
                              <React.Fragment key={index}>
                                <div className="data-row-2">
                                  <div className="title">{item.section_name} {(wellbeingReport?.reopened_sections_ids || [])?.includes(item?.section_id + "") ? <span className='text-danger'>*</span> : ''}</div>
                                  <div className="value">{item.score}%</div>
                              </div>
                              <div className="hr"></div>
                              </React.Fragment>
                            ))
                          }

                          <div className="d-flex justify-content-between">
                            {
                              !wellbeingReport?.user_self_assessment_id !== wellbeingReport?.all_report_ids?.[0]?.user_self_assessment_id &&
                              <div className="text-center view-full-report py-4" 
                                onClick={()=>{navigate(routes.REOPEN_REPORT_FOR_GUEST_ENDPOINT, {state: {guest_id: guestId, user_program_id: userProgramId}})}}>
                                Reopen For Guest
                              </div>
                            }
                            <div className="text-center view-full-report py-4" 
                              onClick={()=>{navigate(routes.VIEW_FULL_WELLBEING_REPORT_ENDPOINT, {state: {user_self_assessment_id: wellbeingReport?.user_self_assessment_id }})}}>
                              View Full Health Assessment
                            </div>
                          </div>
                          </>
                        }
                      </>
                    }
                    <ChangeVisibilityModal 
                      warnModal={visibilityModal} 
                      setWarnModal={setVisibilityModal} 
                      onYes={handleEye}
                      hide={eye}
                      />
                      <WarningModal
                        text={`Are you sure, you want to open Health Assessment for ${guestDetails?.first_name} ${guestDetails?.last_name}?`}
                        warnModal={modal} 
                        buttonText="Open"
                        setWarnModal={setModal} 
                        onClose={()=>{handleOpenAssessment()}}
                        loading={updateLoader}
                        />
                  </div>
                </div>
              </div>
            </>
          }
      </div>
    </div>
  )
}

export default ViewWellbeingReport